body, html {
    margin: 0;
    padding: 0;
    height: 100%;
}

#root {
    height: 100%;
}

.App, .layout {
    height: calc(100% - 70px);
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #263451;
  color: white;
}

a {
    color: white;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

a:visited {
    color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
